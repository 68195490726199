// Initialise Slick slider

( function( $, window, document, undefined ) {

	const defaultArgs = {
		autoplay: false,
		dots: true,
		arrows: false,
		infinite: true,
		adaptiveHeight: true,
		draggable: false
	};

	const $quoteCarousel = $('.js-quote-carousel');

	if ( $quoteCarousel.length ) {

		// Run on a specific selector with default args
		$quoteCarousel.slick( defaultArgs );

		// add class that hides focus outline if clicking instead of keyboard tabbing
		$quoteCarousel.find('.slick-dots button')
			.on('click', function(event) {
				$quoteCarousel.find('.slick-dots').addClass('not-focus-visible');
			})
			.on('blur', function(event) {
				$quoteCarousel.find('.slick-dots').removeClass('not-focus-visible');
			});

	} // /$quoteCarousel.length
	
} )( jQuery, window, document );
