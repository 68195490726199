// Hero Parallax

(function( $ ) {

	let $hero = $('.js-hero');

	$(window).scroll(throttle(function(event) {
		let scrollTop = $(window).scrollTop();

		console.log( scrollTop );

		$hero.css('--offsetY', scrollTop / 3);
	}, 20));
	
})( jQuery );
