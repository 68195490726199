// Lazy Load videos

(function( $ ) {

	const $videos = $('[data-load-video]');

	$videos.each((index, el) => {
		$(el).click((event) => {
			$(el).replaceWith( $(el).data('loadVideo') );
		});
	});
	
})( jQuery );
